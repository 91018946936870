import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

export function Newsletter() {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = new FormData(e.target);
      const email = data.get("email");
      if (!email) {
        setError("The email is required");
        return;
      }
      const res = await addToMailchimp(email);
      if (res.result === "success") setSuccessMessage(res.msg);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);

    // I recommend setting `result` to React state
    // but you can do whatever you want
  };

  return (
    <div className="flex flex-col md:items-baseline">
      <div className="mb-6">
        <h6 className="text-xs font-serif">Join the community</h6>
        <p className="text-xs md:text-sm mt-2 text-white/70">
          Stay tuned for Victory Point updates! Join us and be the first to
          know.
        </p>
      </div>
      {successMessage ? (
        <div className="bg-red-500 px-6 py-4 text-white w-full">
          <IconParkSolidSuccess />
          <p className="text-white mt-3">{successMessage}</p>
          <button
            type="submit"
            className="inline-flex min-w-[8rem] shrink-0 items-center justify-center bg-white text-red-500 px-4 py-3 font-serif text-xs hover:bg-white/95 mt-3"
            onClick={() => setSuccessMessage("")}
          >
            Go back
          </button>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full md:w-2/3 lg:w-full xl:w-3/4"
        >
          <div
            className={`flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 bg-second border ${
              error ? "border-red-500" : "border-white/20"
            } p-3 md:p-2 mb-2`}
          >
            <div className="w-full">
              <input
                name="email"
                type="email"
                required
                placeholder="Enter your email"
                className="w-full bg-second px-4 py-2 placeholder:text-white/30 rounded-none focus:rounded-none"
              />
            </div>
            <button
              type="submit"
              className="inline-flex min-w-[8rem] shrink-0 items-center justify-center bg-red-500 hover:bg-red-600 transition-all ease-in-out px-4 py-3 font-serif text-xs disabled:bg-red-500/40"
              disabled={isLoading}
            >
              {isLoading ? <SvgSpinnersBarsRotateFade /> : "Subscribe"}
            </button>
          </div>
          {error && <p className="text-red-500">{error}</p>}
        </form>
      )}
    </div>
  );
}

function SvgSpinnersBarsRotateFade() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      className="w-4 h-4"
    >
      <g>
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".14"
        />
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".29"
          transform="rotate(30 12 12)"
        />
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".43"
          transform="rotate(60 12 12)"
        />
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".57"
          transform="rotate(90 12 12)"
        />
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".71"
          transform="rotate(120 12 12)"
        />
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          opacity=".86"
          transform="rotate(150 12 12)"
        />
        <rect
          width="2"
          height="5"
          x="11"
          y="1"
          fill="currentColor"
          transform="rotate(180 12 12)"
        />
        <animateTransform
          attributeName="transform"
          calcMode="discrete"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"
        />
      </g>
    </svg>
  );
}

function IconParkSolidSuccess() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      className="w-12 h-12"
    >
      <defs>
        <mask id="ipSSuccess0">
          <g
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
          >
            <path
              fill="#fff"
              stroke="#fff"
              d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012L24 4Z"
            />
            <path stroke="#000" d="m17 24l5 5l10-10" />
          </g>
        </mask>
      </defs>
      <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSSuccess0)" />
    </svg>
  );
}
