import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Button({
  children,
  size,
  type,
  leftIcon,
  rightIcon,
  variant,
  isDisabled,
  onClick,
  className,
  color,
}) {
  const style = {
    size: {
      sm: `h-8 px-4 text-xs`,
      md: `h-10 px-6 text-sm`,
      lg: `h-14 px-10 text-base`,
    },
    variant: {
      solid: `uppercase inline-flex items-center justify-center w-auto whitespace-nowrap bg-${color}-500 group-focus:bg-${color}-900`,
      outline: `uppercase inline-flex items-center justify-center w-auto whitespace-nowrap group-hover:bg-${color}-500/20 group-focus:bg-${color}-500/20`,
    },
  };

  return (
    <button
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      className={classNames(
        className,
        "relative font-serif tracking-widest p-2 group"
      )}
    >
      <span
        className={classNames(
          `absolute left-0 top-0 bottom-0 h-full w-0.5 bg-${color}-500 transition-all ease-linear duration-200`,
          variant === "solid"
            ? `group-focus:bg-${color}-900`
            : `group-focus:bg-${color}-500/20`
        )}
      />
      <span
        className={classNames(
          `absolute right-0 top-0 bottom-0 h-full w-0.5 bg-${color}-500 transition-all ease-linear duration-200`,
          variant === "solid"
            ? `group-focus:bg-${color}-900`
            : `group-focus:bg-${color}-500/20`
        )}
      />
      <span
        className={classNames(
          `absolute top-0 left-0 h-0.5 w-[40%] group-hover:w-[51%] group-focus:w-[51%] bg-${color}-500 transition-all ease-linear duration-200`,
          variant === "solid"
            ? `group-focus:bg-${color}-900`
            : `group-focus:bg-${color}-500/20`
        )}
      />
      <span
        className={classNames(
          `absolute top-0 right-0 h-0.5 w-[40%] group-hover:w-[51%] group-focus:w-[51%] bg-${color}-500 transition-all ease-linear duration-200`,
          variant === "solid"
            ? `group-focus:bg-${color}-900`
            : `group-focus:bg-${color}-500/20`
        )}
      />
      <span
        className={classNames(
          `absolute bottom-0 left-0 h-0.5 w-[40%] group-hover:w-[51%] group-focus:w-[51%] bg-${color}-500 transition-all ease-linear duration-200`,
          variant === "solid"
            ? `group-focus:bg-${color}-900`
            : `group-focus:bg-${color}-500/20`
        )}
      />
      <span
        className={classNames(
          `absolute bottom-0 right-0 h-0.5 w-[40%] group-hover:w-[51%] group-focus:w-[51%] bg-${color}-500 transition-all ease-linear duration-200`,
          variant === "solid"
            ? `group-focus:bg-${color}-900`
            : `group-focus:bg-${color}-500/20`
        )}
      />

      {leftIcon && <span className="mr-4">{leftIcon}</span>}
      <span
        className={classNames(
          style.variant[variant],
          style.size[size],
          "isolate transition-all ease-linear duration-200"
        )}
      >
        {children}
      </span>
      {rightIcon && <span className="ml-4">{rightIcon}</span>}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.elementType.isRequired,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  variant: PropTypes.oneOf(["solid", "outline"]),
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  size: "md",
  type: "button",
  leftIcon: null,
  rightIcon: null,
  variant: "solid",
  isDisabled: false,
  onClick: null,
  color: "red",
};
