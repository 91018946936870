import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";

const index = ({ children }) => (
  <>
    <NavBar />
    <main className="w-full min-h-screen">{children}</main>
    <Footer />
  </>
);

export default index;
