import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export function IntroductionCard({ data }) {
  const image = getImage(data.image);
  return (
    <div className="px-3 md:px-6 space-y-6 md:space-y-8">
      <GatsbyImage image={image} alt={data.title} className="mx-auto" />
      <div className="text-center space-y-2 md:space-y-4">
        <h4 className="text-xs md:text-sm font-bold uppercase font-serif tracking-widest">
          {data.title}
        </h4>
        <p className="text-sm md:text-base">{data.description}</p>
      </div>
    </div>
  );
}

IntroductionCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};
