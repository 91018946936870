import React from "react";
import PropTypes from "prop-types";

export function RoadmapCard({ data }) {
  const { title, items } = data;
  return (
    <div className="relative w-full py-8 px-8 bg-second shadow-lg h-full">
      <div className="absolute inset-0 border-2 border-third" />
      <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
      <h4 className="text-lg md:text-lg font-bold uppercase font-serif tracking-widest leading-loose">
        {title.split("-")[0]}{" "}
        {/* <span className="text-xs text-red-500">/ {title.split("-")[1]}</span> */}
      </h4>
      {/* <div className="absolute top-0 lg:top-8 left-0 bg-slate-800 w-[.125rem] lg:w-full h-full lg:h-[.125rem] lg:my-12">
        <svg
          className="absolute left-0 lg:left-7 top-0 w-6 h-6 translate-x-[calc(-50%_+_1px)] lg:translate-x-0 lg:translate-y-[calc(-50%_+_1px)]"
          viewBox="0 0 792 792"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="99.0145"
            y="396"
            width="420"
            height="420"
            transform="rotate(-45 99.0145 396)"
            fill="#0F172A"
            stroke="url(#paint0_linear_129_3)"
            strokeWidth="140"
          />
          <defs>
            <linearGradient
              id="paint0_linear_129_3"
              x1="280.02"
              y1="396"
              x2="280.02"
              y2="956"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#ff5b5f" />
              <stop offset="1" stopColor="#ff141b" />
            </linearGradient>
          </defs>
        </svg>
      </div> */}
      <ul className="space-y-2 mt-4 lg:pr-6 lg:pt-8">
        {Object.keys(items).map((key) => (
          <li className="flex space-x-3 items-baseline" key={key}>
            <svg
              className="w-3 h-3 shrink-0"
              viewBox="0 0 792 792"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="395.98"
                width="560"
                height="560"
                transform="rotate(-45 0 395.98)"
                fill="url(#paint0_linear_139_2)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_139_2"
                  x1="280"
                  y1="395.98"
                  x2="280"
                  y2="955.98"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#ff5b5f" />
                  <stop offset="1" stopColor="#ff141b" />
                </linearGradient>
              </defs>
            </svg>
            <p className="text-base lg:text-lg text-slate-300">{items[key]}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

RoadmapCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
