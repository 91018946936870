import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {
  Artstation,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from "../../Icons";

export function TeamMemberCard({ data }) {
  const { fullName, position, socialMedia } = data;
  const image = getImage(data.image);

  let socialMediaList = null;

  if (socialMedia.length) {
    socialMediaList = Object.keys(socialMedia).map((key) => {
      if (socialMedia[key].split(".")[1] === "facebook") {
        return (
          <a href={socialMedia[key]} target="_blank" key={key} rel="noreferrer">
            <Facebook className="w-7 h-7 text-white hover:text-red-500 transition-all ease-out duration-300" />
          </a>
        );
      }
      if (socialMedia[key].split(".")[1] === "linkedin") {
        return (
          <a href={socialMedia[key]} target="_blank" key={key} rel="noreferrer">
            <Linkedin className="w-7 h-7 text-white hover:text-red-500 transition-all ease-out duration-300" />
          </a>
        );
      }
      if (socialMedia[key].split(".")[1] === "instagram") {
        return (
          <a href={socialMedia[key]} target="_blank" key={key} rel="noreferrer">
            <Instagram className="w-7 h-7 text-white hover:text-red-500 transition-all ease-out duration-300" />
          </a>
        );
      }
      if (socialMedia[key].split(".")[1] === "twitter") {
        return (
          <a href={socialMedia[key]} target="_blank" key={key} rel="noreferrer">
            <Twitter className="w-7 h-7 text-white hover:text-red-500 transition-all ease-out duration-300" />
          </a>
        );
      }
      if (socialMedia[key].split(".")[1] === "artstation") {
        return (
          <a href={socialMedia[key]} target="_blank" key={key} rel="noreferrer">
            <Artstation className="w-7 h-7 text-white hover:text-red-500 transition-all ease-out duration-300" />
          </a>
        );
      }
      return (
        <a href={socialMedia[key]} target="_blank" key={key} rel="noreferrer">
          <Youtube className="w-7 h-7 text-white hover:text-red-500 transition-all ease-out duration-300" />
        </a>
      );
    });
  }

  return (
    <div className="relative p-2 bg-second shadow-lg grayscale hover:grayscale-0 transition ease-in-out duration-300">
      <div className="absolute inset-0 border-2 border-third" />
      <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
      <GatsbyImage image={image} alt={fullName} className="aspect-[3/4]" />
      <div className="absolute bottom-0 left-0 w-full p-2">
        <div className="p-2 bg-gradient-to-br from-second/50 to-second/60 backdrop-blur-md space-y-1 md:space-y-2">
          <div className="md:space-y-1">
            <span className="text-[.5rem] md:text-[.6rem] font-bold p-1 bg-red-500 uppercase tracking-widest">
              {position}
            </span>
            <h4 className="text-sm md:text-xl font-medium capitalize truncate">
              {fullName}
            </h4>
          </div>
          <div className="flex space-x-2">{socialMediaList}</div>
        </div>
      </div>
    </div>
  );
}

TeamMemberCard.propTypes = {
  data: PropTypes.shape({
    fullName: PropTypes.string,
    position: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
    socialMedia: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};
