import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function IconButton({
  icon,
  size,
  type,
  variant,
  isDisabled,
  onClick,
  className,
}) {
  const style = {
    size: {
      sm: `h-8 w-8 text-lg`,
      md: `h-10 w-10 text-xl`,
      lg: `h-12 w-12 text-2xl`,
    },
    variant: {
      solid:
        "inline-flex items-center justify-center bg-red-500 group-focus:bg-red-900",
      outline:
        "inline-flex items-center justify-center group-hover:bg-red-500/20 group-focus:bg-red-500/20",
    },
  };

  return (
    <button
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      className={classNames(className, "relative p-2 group")}
    >
      <span
        className={classNames(
          "absolute left-0 top-0 bottom-0 h-full w-0.5 bg-red-500 transition-all ease-linear duration-200",
          variant === "solid"
            ? "group-focus:bg-red-900"
            : "group-focus:bg-red-500/20"
        )}
      />
      <span
        className={classNames(
          "absolute right-0 top-0 bottom-0 h-full w-0.5 bg-red-500 transition-all ease-linear duration-200",
          variant === "solid"
            ? "group-focus:bg-red-900"
            : "group-focus:bg-red-500/20"
        )}
      />
      <span
        className={classNames(
          "absolute top-0 left-0 h-0.5 w-[30%] group-hover:w-1/2 group-focus:w-1/2 bg-red-500 transition-all ease-linear duration-200",
          variant === "solid"
            ? "group-focus:bg-red-900"
            : "group-focus:bg-red-500/20"
        )}
      />
      <span
        className={classNames(
          "absolute top-0 right-0 h-0.5 w-[30%] group-hover:w-1/2 group-focus:w-1/2 bg-red-500 transition-all ease-linear duration-200",
          variant === "solid"
            ? "group-focus:bg-red-900"
            : "group-focus:bg-red-500/20"
        )}
      />
      <span
        className={classNames(
          "absolute bottom-0 left-0 h-0.5 w-[30%] group-hover:w-1/2 group-focus:w-1/2 bg-red-500 transition-all ease-linear duration-200",
          variant === "solid"
            ? "group-focus:bg-red-900"
            : "group-focus:bg-red-500/20"
        )}
      />
      <span
        className={classNames(
          "absolute bottom-0 right-0 h-0.5 w-[30%] group-hover:w-1/2 group-focus:w-1/2 bg-red-500 transition-all ease-linear duration-200",
          variant === "solid"
            ? "group-focus:bg-red-900"
            : "group-focus:bg-red-500/20"
        )}
      />
      <span
        className={classNames(
          style.variant[variant],
          style.size[size],
          "isolate transition-all ease-linear duration-200"
        )}
      >
        {icon}
      </span>
    </button>
  );
}

IconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  variant: PropTypes.oneOf(["solid", "outline"]),
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  className: "",
  size: "md",
  type: "button",
  variant: "solid",
  isDisabled: false,
  onClick: null,
};
