import React from "react";
import PropTypes from "prop-types";

export function Container({ children, className }) {
  return (
    <div className={`container mx-auto px-6 ${className}`}>{children}</div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Container.defaultProps = {
  className: "",
};
