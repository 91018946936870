import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { Container } from "../../Shared";
import Logo from "../../../assets/images/victory_point_logo.svg";
import { Discord, Linktr, Telegram, Twitter, Youtube } from "../../Icons";
import { Newsletter } from "../../Shared/Newsletter";

const query = graphql`
  {
    menu: allMenuItemsJson {
      nodes {
        id
        href
        title
      }
    }
  }
`;

function Footer() {
  const data = useStaticQuery(query);
  const menu = data?.menu?.nodes;

  return (
    <footer className="bg-[#0E0F14]">
      <Container className="h-full">
        <div className="divide-y divide-white/10">
          <div className="flex flex-col lg:flex-row gap-12 py-10 md:pt-24">
            <div className="lg:basis-1/2">
              <Link to="/" className="flex-shrink-0">
                <img src={Logo} alt="victory point" className="w-32" />
              </Link>
              <p className="text-white mt-6 max-w-md">
                Victory Point is a free-to-play, multiplayer, first person
                shooter game built on Unreal Engine 5, developed and published
                by Last Bullet Studios.
              </p>
              <div className="mt-12">
                <Newsletter />
              </div>
            </div>
            <div className="flex flex-wrap gap-y-12 lg:basis-1/2">
              <div className="basis-1/2 md:basis-1/3 space-y-4">
                <h4 className="text-xs uppercase text-red-500 font-serif">
                  about
                </h4>
                <ul className="space-y-4">
                  {menu
                    .filter(
                      ({ title }) => !["v-point", "v-island"].includes(title)
                    )
                    .map((menuItem) => (
                      <li key={menuItem.id}>
                        <Link
                          to={`${menuItem.href}`}
                          className="text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                        >
                          {menuItem.title}
                        </Link>
                      </li>
                    ))}
                  <li>
                    <a
                      href="https://www.canva.com/design/DAFPgrvNA8U/3I1Lc5Vwq3ZPPqeGY_bzCQ/view?utm_content=DAFPgrvNA8U&utm_campaign=designshare&utm_medium=link&utm_source=viewer&fbclid=IwAR1VgtkKulPsQQuBa7opYdXr4zbl9EIdnabjkmeknwejS-bDQkx0Dr3yt9M"
                      target="_blank"
                      className="text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      PITCH DECK
                    </a>
                  </li>
                </ul>
              </div>
              <div className="basis-1/2 md:basis-1/3">
                <h4 className="text-xs uppercase text-red-500 font-serif">
                  menu
                </h4>
                <ul className="mt-6 space-y-4">
                  {menu
                    .filter(
                      ({ title }) =>
                        !["v-team", "v-friends", "v-roadmap"].includes(title)
                    )
                    .map((menuItem) => (
                      <li key={menuItem.id}>
                        <Link
                          to={`${menuItem.href}`}
                          className="text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                        >
                          {menuItem.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="basis-full md:basis-1/3 pr-6">
                <h4 className="text-xs uppercase text-red-500 font-serif">
                  Follow us
                </h4>
                <ul className="mt-6 flex flex-col space-y-4">
                  <li>
                    <a
                      href="https://linktr.ee/victorypoint.io"
                      target="_blank"
                      className="flex items-center space-x-4 text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Linktr className="w-6" />
                      <span>Linktr</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://discord.gg/YurGJz7mAm"
                      target="_blank"
                      className="flex items-center space-x-4 text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Discord className="w-6" />
                      <span>discord</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/VictoryPoint_io"
                      target="_blank"
                      className="flex items-center space-x-4 text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Twitter className="w-6" />
                      <span>Twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://t.me/+uPhVrPJYg85lNmQ0"
                      target="_blank"
                      className="flex items-center space-x-4 text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Telegram className="w-6" />
                      <span>Telegram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UCp_8JhM_jK7XWne3hfpHLVQ"
                      target="_blank"
                      className="flex items-center space-x-4 text-white/70 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Youtube className="w-6" />
                      <span>Youtube</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="py-8 space-y-2 md:space-y-0 md:flex md:justify-between">
            <p className="text-white/40 text-sm">
              Copyright © Victory Point {new Date().getFullYear()}. All rights
              reserved.
            </p>
            <p className="text-white/40 text-sm">
              Design/Code by{" "}
              <Link
                href="https://github.com/Amine-AD"
                className="text-white font-semibold hover:underline"
              >
                Amine-AD
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
