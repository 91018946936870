module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Victory Point Website","short_name":"Victory Point","description":"Victory Point is a AAA-rated Play-to-Earn (P2E) Multiplayer First Person Shooter (FPS) game built on the Unreal Engine and powered by blockchain and player ownership.","lang":"en","icon":"/opt/build/repo/src/assets/images/favicon.svg","start_url":"/","background_color":"#0f172a","theme_color":"#00ffcc","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f212bfd92ffb664ff5f1ccf817d6dcf"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
