import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function GooglePlayIcon({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      viewBox="0 0 48 52"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-[1em] h-[1em]", className)}
    >
      <path
        d="M.209 2.457A5.183 5.183 0 0 0 0 3.901v44.206c0 .53.085.995.204 1.427l24.81-23.506L.21 2.457ZM34.697 16.853 5.73.74C4.574.037 3.437-.14 2.477.104l24.915 23.674 7.305-6.925ZM45.377 22.795l-7.731-4.3-7.92 7.5 8.067 7.668 7.522-4.13c3.673-2.093 2.333-5.462.062-6.738ZM2.403 51.876c.975.272 2.14.109 3.324-.611L34.784 35.31l-7.438-7.067L2.403 51.876Z"
        fill="#fff"
      />
    </svg>
  );
}

GooglePlayIcon.propTypes = {
  className: PropTypes.string,
};

GooglePlayIcon.defaultProps = {
  className: "",
};
