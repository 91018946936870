import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export function ModeCard({ data }) {
  const image = getImage(data.image);
  return (
    <div className="relative bg-second shadow-lg">
      <div className="absolute inset-0 border-2 border-third" />
      <span className="absolute left-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute left-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 top-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute right-0 bottom-0 h-4 w-0.5 bg-red-500" />
      <span className="absolute top-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute top-0 right-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 left-0 h-0.5 w-4 bg-red-500" />
      <span className="absolute bottom-0 right-0 h-0.5 w-4 bg-red-500" />
      <div className="py-8 px-8 space-y-8">
        <GatsbyImage image={image} alt={data.title} className="mx-auto" />
        <div className="text-center space-y-6">
          <h4 className="text-xs md:text-sm font-bold uppercase font-serif tracking-widest">
            {data.title}
          </h4>
          <p className="text-sm md:text-base">{data.description}</p>
        </div>
      </div>
    </div>
  );
}

ModeCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.objectOf(PropTypes.object),
  }).isRequired,
};
