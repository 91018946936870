import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function UnrealIcon({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 330 333"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-[1em] h-[1em]", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M329.837 204.852C325.12 227.572 304.158 285.893 237.3 317.471L210.455 287.28L165.15 332.821C139.373 332.476 114.025 326.175 91.0862 314.409C68.1479 302.644 48.241 285.733 32.9215 264.999C37.7331 266.499 42.7025 267.435 47.73 267.788C55.1512 267.924 63.181 265.202 63.181 252.743V129.845C63.2852 126.686 62.6498 123.547 61.3254 120.677C60.0011 117.807 58.0243 115.286 55.553 113.315C53.0816 111.345 50.1839 109.979 47.0911 109.327C43.9983 108.675 40.7959 108.755 37.7393 109.56C16.7605 114.395 0.0078848 166.732 0.0078848 166.732C-0.227337 142.568 4.80298 118.642 14.7491 96.6189C24.6952 74.5956 39.3185 55.0026 57.6024 39.2021C81.668 18.3862 111.127 4.79644 142.583 0C119.677 13.0505 106.812 34.3336 106.812 52.185C106.812 80.9231 124.123 77.4407 129.245 73.2145V239.084C130.118 241.165 131.254 243.125 132.626 244.916C134.914 248.082 137.926 250.654 141.411 252.418C144.896 254.183 148.752 255.088 152.658 255.059C169.985 255.059 192.469 235.28 192.469 235.28V101.023C192.469 87.3638 182.174 70.8648 171.862 65.2017C171.862 65.2017 190.947 61.8207 205.671 73.0962C208.437 69.7171 211.377 66.484 214.479 63.4098C248.846 29.6848 281.219 20.0829 308.182 15.2819C308.182 15.2819 259.158 53.8078 259.158 105.503C259.158 143.944 260.155 237.698 260.155 237.698C278.345 255.262 305.392 229.888 329.837 204.852Z"
        fill="#ffffff"
      />
    </svg>
  );
}

UnrealIcon.propTypes = {
  className: PropTypes.string,
};

UnrealIcon.defaultProps = {
  className: "",
};
