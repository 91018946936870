import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function PolygonIcon({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 61 54"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-[1em] h-[1em]", className)}
    >
      <g clipPath="url(#clip0_178_24)">
        <path
          d="M45.5827 16.7585C44.4882 16.1331 43.081 16.1331 41.8301 16.7585L33.0739 21.9184L27.1321 25.202L18.5323 30.3619C17.4378 30.9874 16.0305 30.9874 14.7796 30.3619L8.05609 26.2965C6.96156 25.6711 6.17976 24.4202 6.17976 23.013V15.1949C6.17976 13.944 6.8052 12.6931 8.05609 11.9113L14.7796 8.00229C15.8741 7.37685 17.2814 7.37685 18.5323 8.00229L25.2558 12.0677C26.3503 12.6931 27.1321 13.944 27.1321 15.3513V20.5112L33.0739 17.0712V11.755C33.0739 10.5041 32.4484 9.25318 31.1975 8.47137L18.6886 1.1224C17.5941 0.496959 16.1869 0.496959 14.936 1.1224L2.11437 8.62773C0.863481 9.25318 0.238037 10.5041 0.238037 11.755V26.4529C0.238037 27.7038 0.863481 28.9547 2.11437 29.7365L14.7796 37.0854C15.8741 37.7109 17.2814 37.7109 18.5323 37.0854L27.1321 32.0819L33.0739 28.6419L41.6737 23.6384C42.7682 23.013 44.1755 23.013 45.4264 23.6384L52.1499 27.5474C53.2444 28.1729 54.0262 29.4238 54.0262 30.831V38.6491C54.0262 39.8999 53.4008 41.1508 52.1499 41.9326L45.5827 45.8417C44.4882 46.4671 43.081 46.4671 41.8301 45.8417L35.1065 41.9326C34.012 41.3072 33.2302 40.0563 33.2302 38.6491V33.6455L27.2885 37.0854V42.2454C27.2885 43.4963 27.9139 44.7471 29.1648 45.5289L41.8301 52.8779C42.9246 53.5034 44.3319 53.5034 45.5827 52.8779L58.248 45.5289C59.3425 44.9035 60.1243 43.6526 60.1243 42.2454V27.3911C60.1243 26.1402 59.4989 24.8893 58.248 24.1075L45.5827 16.7585Z"
          fill="#ffffff"
        />
      </g>
      <defs>
        <clipPath id="clip0_178_24">
          <rect
            width="60.0426"
            height="52.381"
            fill="white"
            transform="translate(0.238037 0.80957)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

PolygonIcon.propTypes = {
  className: PropTypes.string,
};

PolygonIcon.defaultProps = {
  className: "",
};
