exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-v-friends-jsx": () => import("./../../../src/pages/v-friends.jsx" /* webpackChunkName: "component---src-pages-v-friends-jsx" */),
  "component---src-pages-v-island-jsx": () => import("./../../../src/pages/v-island.jsx" /* webpackChunkName: "component---src-pages-v-island-jsx" */),
  "component---src-pages-v-roadmap-jsx": () => import("./../../../src/pages/v-roadmap.jsx" /* webpackChunkName: "component---src-pages-v-roadmap-jsx" */),
  "component---src-pages-v-team-jsx": () => import("./../../../src/pages/v-team.jsx" /* webpackChunkName: "component---src-pages-v-team-jsx" */)
}

