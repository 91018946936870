import React from "react";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function BinanceChainIcon({ className }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 128 128"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("w-[1em] h-[1em]", className)}
    >
      <path d="M91.75 80.02V67.53l-10.16 5.86v12.5l10.16-5.87z" />
      <path d="m91.75 47.98-10.54-6.09L64 51.83l-17.21-9.94-10.54 6.09v11.07l17.5 10.11v20.97L64 96.05l10.25-5.92V69.16l17.5-10.11V47.98zM109.35 48.89V37.82l-10.54-6.09-10.26 5.92 10.54 6.09v11.07l10.26-5.92zM28.91 54.81V43.74l10.54-6.09-10.26-5.92-10.54 6.09v11.07l10.26 5.92z" />
      <path d="m36.25 80.02 10.16 5.87v-12.5l-10.16-5.86v12.49zM74.25 98.61 64 104.53l-10.25-5.92v11.84L64 116.37l10.25-5.92V98.61zM73.87 37.65 64 31.95l-9.87 5.7 9.87 5.7 9.87-5.7zM99.09 63.29v20.97l-17.5 10.11v11.84l27.76-16.03V57.37l-10.26 5.92z" />
      <path d="m46.41 94.37-17.5-10.11V63.29l-10.26-5.92v32.81l27.76 16.03V94.37zM46.79 33.41 64 23.48l17.21 9.93 10.26-5.92L64 11.63 36.53 27.49l10.26 5.92z" />
    </svg>
  );
}

BinanceChainIcon.propTypes = {
  className: PropTypes.string,
};

BinanceChainIcon.defaultProps = {
  className: "",
};
